@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");

body {
  font-family: "Poetsen One", sans-serif;
  padding: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.container {
  margin-top: 50px;
}

.card {
  padding: 20px;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-header {
  padding: 15px 0;
}

.btn {
  margin-top: 10px;
}

.nav-item:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

h1 {
  padding: 20px;
  border-radius: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 50px;
  height: 50px;
  display: block;
  background-size: 100%, 100%;
}

.carousel-control-prev,
.carousel-control-next {
  filter: invert(100%);
}
